<template>
  <div class="ConfirmationView">
    <ApplicationBanner content="<h1>Application confirmation</h1>" />
    <b-container class="ConfirmationContent primary--text">
      <b-row>
        <b-col aria-label="main">
          <div
            class="ConfirmationContent__Summary mt-0 mt-md-5 mt-lg-5 pl-2 pl-md-12 pl-lg-12"
          >
            <div class="ConfirmationContent__Success-icon"></div>
            <div class="ConfirmationContent__body">
              <div v-if="fromEhub">
                <h4><b>Your application has been submitted.</b></h4>
                <h4>
                  Application reference ID: <b>{{ applicationId }}</b>
                </h4>
              </div>
              <div v-else>
                <h4><b>You've submitted your application</b></h4>
                <h4>
                  Your application ID is: <b>{{ applicationId }}</b>
                </h4>
              </div>
              <p v-if="fromEhub" class="mt-3 d-none d-sm-flex">
                We’ve sent a copy of your application and its ID to &nbsp;
                <strong>{{ emailAddress }}</strong>
              </p>
              <p v-else class="mt-3 d-none d-sm-flex">
                We’ve sent a copy of your application and its ID number to
                &nbsp; <strong>{{ emailAddress }}</strong>
              </p>
            </div>
          </div>
          <p class="mt-3 mb-5 d-sm-none">
            We’ve sent a copy of your application and its ID to
            <strong>{{ emailAddress }}</strong>
          </p>

          <div class="white-card">
            <div class="detail d-flex flex-column flex-lg-row flex-md-row">
              <div class="detail__icon detail__icon--survey"></div>
              <div class="detail__body">
                <h4
                  class="text-center pb-6 pt-6 text-md-left text-lg-left pb-md-0 pt-md-0 pb-lg-0 pt-lg-0"
                >
                  <b>Complete our 1 minute survey.</b>
                </h4>
                <p
                  class="mt-3 text-center text-md-left text-lg-left pb-6 pb-md-2 pb-lg-2"
                >
                  Our goal is to create the best possible product, and your
                  thoughts, ideas, and suggestions play a major role in helping
                  us identify opportunities to improve.
                  <a
                    :href="surveyLink"
                    target="_blank"
                    rel="external noopener"
                    class="link-outline"
                    ><strong>Click here to start</strong></a
                  >.
                </p>
              </div>
            </div>
          </div>
          <div class="pb-2">
            <div
              class="detail mt-5 d-flex flex-column flex-lg-row flex-md-row"
            />
          </div>

          <div class="white-card">
            <div v-if="fromEhub">
              <div class="detail d-flex flex-column flex-lg-row flex-md-row">
                <div class="detail__icon detail__icon--hub"></div>
                <div class="detail__body">
                  <h4
                    class="text-center pb-6 pt-6 text-md-left text-lg-left pb-md-0 pt-md-0 pb-lg-0 pt-lg-0"
                  >
                    <b>Track applications from the dashboard.</b>
                  </h4>
                  <p
                    class="mt-3 text-center text-md-left text-lg-left pb-6 pb-md-2 pb-lg-2"
                  >
                    Track the status of applications or start a new application
                    from the Online Enrolment dashboard.
                  </p>
                  <v-btn
                    color="primary"
                    @click="gotoEhub"
                    class="mt-2 mb-2 pa-3 headline"
                  >
                    <v-icon class="mr-2" color="white"
                      >mdi-logout-variant</v-icon
                    >
                    Go to the dashboard
                  </v-btn>
                </div>
              </div>
              <hr />
            </div>

            <div class="detail d-flex flex-column flex-lg-row flex-md-row">
              <div class="detail__icon detail__icon--email"></div>

              <div class="detail__body">
                <h4
                  class="text-center pb-6 pt-6 text-md-left text-lg-left pb-md-0 pt-md-0 pb-lg-0 pt-lg-0"
                >
                  <b>Check your inbox for confirmation of your application.</b>
                </h4>
                <p
                  class="mt-3 text-center text-md-left text-lg-left pb-6 pb-md-2 pb-lg-2"
                >
                  This email will include a copy of your application, your
                  application reference number and a list of the documentation
                  you will need to take to {{ schoolName }}.
                </p>
              </div>
            </div>

            <hr />
            <div class="detail mt-5 d-flex flex-column flex-lg-row flex-md-row">
              <div class="detail__icon detail__icon--papers"></div>

              <div class="detail__body">
                <h4
                  class="text-center pb-6 pt-6 text-md-left text-lg-left pb-md-0 pt-md-0 pb-lg-0 pt-lg-0"
                >
                  <b>{{ description | replaceSchool(schoolName) }}</b>
                </h4>
                <p class="mt-3">
                  <b>For example:</b>
                </p>
                <ul class="mt-3 pl-3 pl-md-6 pl-lg-6">
                  <li>
                    Student's identity documents, e.g. birth certificate,
                    passport
                  </li>
                  <li>Student's Immunisation History Statement</li>
                  <li>
                    Documents to verify the student’s current residential
                    address, e.g. residential lease, utility bills, council
                    rates notice
                  </li>
                  <li>
                    Student's passport, visa and travel documents (if the
                    student is not an Australian citizen)
                  </li>
                </ul>
                <p class="mt-3">
                  <b
                    >Also, bring any of the following documents as detailed in
                    your application:</b
                  >
                </p>
                <ul class="mt-3 pl-3 pl-md-6 pl-lg-6">
                  <li>
                    Copies of medical/healthcare or emergency action plans
                  </li>
                  <li>Evidence of any disability or other support needs</li>
                  <li>
                    Copies of any family law or other relevant court orders
                  </li>
                  <li>
                    <a
                      href="https://education.nsw.gov.au/content/dam/main-education/public-schools/going-to-a-public-school/media/documents/Residential-address-check.pdf"
                      target="_blank"
                      rel="external noopener"
                      class="link-outline"
                      ><strong>Additional documents</strong></a
                    >
                    to verify the student’s current residential address
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import endPoint from '@/store/endPoint'
import _startCase from 'lodash/startCase'
import _capitalize from 'lodash/capitalize'
import ehubUtil from '@/utils/ehub'
import jwtDecode from 'jwt-decode'

export default {
  name: 'ConfirmationView',
  components: {
    ApplicationBanner
  },
  beforeRouteLeave(to, from, next) {
    if (this.fromEhub) {
      window.backButtonClicked = false
      this.$store.dispatch('openModal', {
        componentName: 'ModalEhubConfirmation',
        size: 'small'
      })
    } else {
      next()
    }
  },
  data() {
    return {
      globalWebContentRef: null,
      webCustomisation: null,
      isStamped: false,
      isSnswLogin: false
    }
  },
  computed: {
    description() {
      const selectedCalendarYear =
        this.$store.state.application.meta.calendarYear
      const isCurrentCalendarYear =
        selectedCalendarYear == new Date().getFullYear()
      const capitalizedSchoolName = _startCase(this.schoolName)
      const hardCodedCurrentYearDesciption = `${capitalizedSchoolName} will contact you within two weeks to confirm which original documents must be sighted to complete this application process. School holiday periods may increase assessment times.`
      const hardCodedNextYearDesciption = `${capitalizedSchoolName} will contact you in October this year to confirm which original documents must be sighted to complete this application process. School holiday periods may increase assessment times.`
      if (!this.globalWebContentRef) {
        return isCurrentCalendarYear
          ? hardCodedCurrentYearDesciption
          : hardCodedNextYearDesciption
      }

      if (!this.webCustomisation) {
        const defaultCurrentYearTimeFrames =
          this.globalWebContentRef.currentYearTimeFrames.find(
            (timeFrame) => timeFrame.default === true
          )
        const defaultCurrentYearDesciption =
          defaultCurrentYearTimeFrames.description.schoolToParent
        const defaultNextYearTimeFrames =
          this.globalWebContentRef.nextYearTimeFrames.find(
            (timeFrame) => timeFrame.default === true
          )
        const defaultNextYearDesciption =
          defaultNextYearTimeFrames.description.schoolToParent
        return isCurrentCalendarYear
          ? defaultCurrentYearDesciption
          : defaultNextYearDesciption
      }

      const isParentToSchool = this.webCustomisation.whoWillContact === 'parent'
      const customisedCurrentYearTimeFrames =
        this.globalWebContentRef.currentYearTimeFrames.find(
          (timeFrame) =>
            timeFrame.active === true &&
            timeFrame.code === this.webCustomisation.currentYearTimeFrames.code
        )
      const currentYearDescription = customisedCurrentYearTimeFrames.description
      const customisedNextYearTimeFrames =
        this.globalWebContentRef.nextYearTimeFrames.find(
          (timeFrame) =>
            timeFrame.active === true &&
            timeFrame.code === this.webCustomisation.nextYearTimeFrames.code
        )
      const nextYearDescription = customisedNextYearTimeFrames.description

      if (isCurrentCalendarYear) {
        return isParentToSchool
          ? currentYearDescription.parentToSchool
          : currentYearDescription.schoolToParent
      }

      // if the user select the next calendar year
      return isParentToSchool
        ? nextYearDescription.parentToSchool
        : nextYearDescription.schoolToParent
    },
    schoolName() {
      try {
        return this.$store.state.application.schoolName || 'the school'
      } catch (e) {
        return 'the school'
      }
    },
    applicationId() {
      try {
        return this.$store.state.submittedApplicationId || ''
      } catch (e) {
        return ''
      }
    },
    emailAddress() {
      const curCarerEmail = this.parentCarerEmail()
      if (curCarerEmail) {
        return curCarerEmail
      }
      return 'your email address'
    },
    fromEhub() {
      return ehubUtil.isFromEhub()
    },
    isSCTApplication() {
      return ehubUtil.isSCTApplication()
    },
    surveyLink() {
      let surveyLink = 'https://digitalnsw.typeform.com/to/lsSgxi'
      if (this.fromEhub) {
        const stampedParent = this.isStamped ? 'yes' : 'no'
        const loginType = this.isSnswLogin ? 'snsw' : 'otp'

        let appSrc = 'core'
        const eoiID = this.$store.state?.application?.eoiID
        if (eoiID) {
          appSrc = 'y67t'
        } else if (this.isSCTApplication) {
          appSrc = 'collegiate'
        }
        surveyLink = `https://www.surveymonkey.com/r/NLWRQ2B?src=${appSrc}&st=${stampedParent}&auth=${loginType}`
      }
      return surveyLink
    }
  },
  filters: {
    replaceSchool(value, schoolName) {
      if (!value) {
        return ''
      }
      value = value.toString().replace('<School>', _startCase(schoolName))
      const isCapitalized = value.indexOf('the school') === 0

      return isCapitalized ? _capitalize(value) : value
    }
  },
  methods: {
    parentCarerEmail() {
      const parentCarers = this.$store.state.application.parentCarers
      if (parentCarers) {
        const curDeclarer = parentCarers.find(
          (pc) =>
            pc.meta.isCompletingApplication &&
            pc.parentCarerGivenName &&
            pc.parentCarerFamilyName
        )
        if (curDeclarer) {
          return curDeclarer['parentCarerEmail']
        }
      }
      return ''
    },
    gotoEhub() {
      window.location.href = `${window.location.origin}/dashboard`
    }
  },
  created() {
    const idToken = window.localStorage.getItem('id_token')
    if (idToken) {
      const decodedJwt = jwtDecode(idToken)
      if (decodedJwt) {
        const customPid = decodedJwt['custom:pid']
        if (customPid) {
          this.isStamped = true
        }
        const cognitoUsername = decodedJwt['cognito:username']
        if (cognitoUsername && String(cognitoUsername).startsWith('SNSW')) {
          this.isSnswLogin = true
        }
      }
    }
  },
  async mounted() {
    this.$gtm.trackView('ConfirmationView', '/confirmation')
    const { data: globalWebContentRefData } =
      await endPoint.getGlobalWebContentRef()
    const { data: webCustomisationData } = await endPoint.getWebCustomisation(
      this.$store.state.application.schoolCode
    )
    this.globalWebContentRef = globalWebContentRefData.local || null
    this.webCustomisation = webCustomisationData.local || null
  }
}
</script>

<style lang="scss">
#app {
  .ConfirmationView {
    margin: 0;
    padding: 0 0 40px;
    .ApplicationBanner {
      height: 102px;
      padding: 0px 15px;
      .container {
        .bannerTitle {
          position: absolute;
          transform: none;
          bottom: 10px;
          top: auto;
        }
      }
    }
    .ConfirmationContent {
      margin-top: 80px;
    }

    h4 {
      font-weight: normal;
      font-size: 2rem;
    }

    p,
    li {
      font-size: 1.4rem;
    }

    p {
      margin-bottom: 0;
    }

    ul {
      > li + li {
        margin-top: $content-padding;
      }
    }

    hr {
      border: 1px solid #e4e4e6;
    }
    a:focus {
      outline: 1px solid $color-primary;
    }
    .alert {
      padding: 1rem;
      border: 1px solid;
      border-radius: 0.2rem;
      color: #212529;
    }

    .alert-success {
      padding-top: $content-padding * 2;
      padding-bottom: $content-padding * 2;
      border-color: #a2d8ae;
    }

    .alert-warning {
      border-color: $color-warning-border;
      background-color: $color-warning;
    }

    .detail {
      display: flex;
      margin-right: 5px;

      .detail__icon {
        flex: 0 0 7rem;
        height: 7rem;
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;

        &--email {
          background-image: url(../assets/icon-email.svg);
        }

        &--papers {
          background-image: url(../assets/icon-documents.svg);
        }

        &--survey {
          background-image: url(../assets/icon-clipboard.svg);
        }

        &--hub {
          background-image: url(../assets/icon-hub.svg);
        }
      }

      .detail__body {
        margin-left: 3rem;
      }
    }

    .ConfirmationContent__Summary {
      display: flex;
      padding: 50px 0px 50px 50px;

      .ConfirmationContent__Success-icon {
        flex: 0 0 7rem;
        height: 7rem;
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/icon-success-green.svg);
      }

      .ConfirmationContent__body {
        margin-left: 3rem;
      }
    }

    .bannerTitle {
      position: absolute;
      transform: none;
      bottom: 0;
      top: auto;
      h1 {
        font-size: 40px;
      }
    }
  }
}

.link-outline {
  &:focus {
    outline-style: auto;
  }
}

@include desktop {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        h1 {
          font-size: 40px;
        }
        .bgLeftElement {
          left: -344px;
          bottom: -282px;
        }
        .bgRightElement {
          right: -210px;
          bottom: -110px;
        }
      }
    }
  }
}
@include tablet-wide {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        h1 {
          font-size: 40px;
        }
        .bgLeftElement {
          left: -344px;
          bottom: -282px;
        }
      }
    }
  }
}
@include mobile {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        height: 68px;
        max-height: 68px;
        .headline {
          .bannerTitle {
            left: 0;
            h1 {
              padding: 0 15px;
            }
          }
          .bgLeftElement {
            height: 130px;
            left: -115px;
            bottom: -80px;
          }
          .bgRightElement {
            height: 90px;
            right: -400px;
            bottom: -38px;
          }
        }
      }
    }
  }
}
</style>
