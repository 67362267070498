import findSreSeeOption from './findSreSeeOption'
// import fnIsSreSelectionDeleted from './isSreSelectionDeleted'
import getSreSeeAmaVarsFromString from './getSreSeeAmaVarsFromString'

function verifySreseeString({ store, schoolCustomisationFile }) {
  if (
    store.state.application.meta.visitedSections.includes(
      'supplementaryDetails'
    ) &&
    store.state.application.selectedSreOrSeeOrAmaOption === ''
  ) {
    return []
  }

  const isValidSreSelection = Boolean(
    findSreSeeOption({
      tokenisedSreSeeSelection: getSreSeeAmaVarsFromString(
        store.state.application.selectedSreOrSeeOrAmaOption
      ),
      schoolCustomisationFile
    }) || store.state.application.selectedSreOrSeeOrAmaOption === ''
  )
  // TODO: this is the right way to check for deletion but it conflicts with prepareSchoolOfferSREAndSEE in actions.js
  // const isSreSelectionDeleted = fnIsSreSelectionDeleted({
  //   tokenisedSreSeeSelection: getSreSeeAmaVarsFromString(
  //     store.state.application.selectedSreOrSeeOrAmaOption
  //   ),
  //   schoolCustomisationFile
  // })

  if (!isValidSreSelection) {
    if (
      store.state.application.meta.visitedSections.includes(
        'supplementaryDetails'
      )
    ) {
      store.commit('set', [
        'application.meta.visitedSections',
        store.state.application.meta.visitedSections.filter(
          vs => vs !== 'supplementaryDetails'
        )
      ])
    }
    return [
      {
        section: 'supplementaryDetails',
        patch: {
          selectedSreOrSeeOrAmaOption: ''
        }
      }
    ]
  } else return []
}

export default function generateVerificationArtifacts({ store }) {
  const schoolCustomisationFile = store.state.schoolCustomisation
    ? store.state.schoolCustomisation.data
    : null
  return [...verifySreseeString({ store, schoolCustomisationFile })]
}
